<template>
  <div class="block w-full overflow-x-auto pb-6">
    <table class="items-center w-full bg-transparent border-collapse table-auto ">
      <thead>
      <tr>
        <th v-for="(column,id) in columns" :key="id"
            class="px-6 align-middle justify-center border border-solid py-3 text-s uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left
              bg-blueGray-50 text-blueGray-500 border-blueGray-100 pl-10"
        >
          {{ column.title }}
        </th>
        <th
            style="width: 100px"
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold
              text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
        ></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="data.length === 0">
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-s whitespace-nowrap p-4 text-left pl-10">
          <span class="text-gray-500">Пусто</span>
        </td>
      </tr>
      <tr :key="object.id" v-for="(object) in data">
        <td v-for="(column,id) in columns" :key="id"
            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-s whitespace-nowrap p-4 text-left pl-10">
          <template v-if="column.badge">
            <template v-if="column.array">
              <span v-for="item in column.value(object)" :key="item"
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 uppercase last:mr-0 mr-1">
                {{ item }}
              </span>
            </template>
            <template v-else>
              <span
                  class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 uppercase last:mr-0 mr-1">
                {{ column.value(object) }}
              </span>
            </template>
          </template>
          <template v-else-if="column.externalUrl">
            <a :href="column.href(object)" target="_blank">{{ column.value(object) }}</a>
          </template>
          <template v-else-if="column.internalUrl">
            <a :href="column.href(object)">{{ column.value(object) }}</a>
          </template>
          <template v-else>
            <span class="font-bold text-blueGray-600">
              {{ column.value(object) }}
            </span>
          </template>
        </td>
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4">
          <table-actions-dropdown :id="object.id" :actions="actions"
                                  @delete="(id) => $emit('delete', id)"
                                  @update="(id) => $emit('update', id)"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import TableActionsDropdown from '@/components/Dropdowns/TableActionsDropdown.vue'

export default {
  name: 'TableList',
  components: {TableActionsDropdown},
  props: ['columns', 'actions', 'data']
}
</script>
