import authHeader from '@/services/api/auth-header'
import http from '@/services/api/http'
import CrudService from '@/services/crud.service.js'

class SchoolService extends CrudService {

    constructor() {
        super('schools')
    }

    importSchools(entity) {
        return http.post('schools/import', entity, {
            headers: authHeader()
        })
    }
}

export default new SchoolService()