import authHeader from '@/services/api/auth-header'
import http from '@/services/api/http'
import CrudService from '@/services/crud.service.js'

class AccountService extends CrudService {

    constructor() {
        super('accounts')
    }

    recovery(email) {
        return http.post('accounts/recovery', {}, {
            headers: authHeader(),
            params: {
                email
            }
        })
    }

    recoveryConfirm(email, code, password) {
        return http.post('accounts/recovery/confirm', {}, {
            headers: authHeader(),
            params: {
                email,
                code,
                password
            }
        })
    }

}

export default new AccountService()